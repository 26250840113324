import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import Header from "../components/Header";

const index = () => {
  const data = useStaticQuery(graphql`
    query PhotosEurope {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "posts/europe" }
        }
      ) {
        edges {
          node {
            extension
            dir
            modifiedTime
            id
            name
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  console.log(data, "data");

  return (
    <main>
      <title>{`:)`}</title>
      <Header showBack={true} />

      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 1200: 3, 1700: 4, 2200: 5 }}
      >
        <Masonry gutter={"16px"}>
          {data.allFile.edges.map((edge) => {
            return (
              <Img
                key={edge.node.id}
                className="object"
                fluid={edge.node.childImageSharp.fluid}
              />
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </main>
  );
};

export default index;
